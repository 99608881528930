import React, { useEffect, useRef } from "react";
import { useDrop, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import Icon from "./Icon";

const DraggableHeader = ({ 
  column, 
  index, 
  reoder, 
  cellCn,
  setMapField,
  columnConfig,
  resizer
  }) => {
  const ref = useRef();

  const [, drop] = useDrop({
    accept: "column",
    drop: (item) => {
      reoder(item, index );
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "column",
    item: () => {
      return {
        id: column.id,
        index: column.index,
        header: column.header
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.5 : 1;

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const accessorKey = column.column.columnDef.accessorKey;

  return (
    <th
      className={ cellCn( accessorKey ) }
      style={{ 
        opacity, 
        background: isDragging ? "red" : "",
        width: column.getSize(),
        maxWidth: column.getSize(),
      }}
    >
      <div 
        ref={ref}
        className="flex justify-between items-center gap-2 max-w-full relative group drag-el"
        >
        <div
            className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                      rounded-full bg-white border border-red text-grey
                      aspect-square p-1 leading-none
                      hidden group-hover:block">
          <Icon name="up-down-left-right" />
        </div>
        <button
          className="overflow-hidden"
          style={{ width: 'calc( 100% - 2rem )'}}
          onClick={ () => setMapField( accessorKey ) }>
          { column.column.columnDef.header() }
        </button>

        <div className="flex justify-between items-center gap-1">
          { columnConfig }

          { resizer }
        </div>
      </div>
    </th>
  );
};

export default DraggableHeader;
