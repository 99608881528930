import { useCallback, useEffect, useRef, useState } from "react";
import Icon from "./Icon";

const VideoButton = ( { link = null, list = [] } ) => {
  const [showVideoModal, setShowVideoModal] = useState( false );
  const video = useRef( null );
  const modal = useRef( null );

  const blur = useCallback( () => video?.current?.pause(), [video]);
  const focus = useCallback( () => showVideoModal && video?.current?.play(), [video,showVideoModal]);
  const keydown = useCallback( (e) => showVideoModal && e.code === 'Escape' && setShowVideoModal( false ), [showVideoModal]);

  useEffect(() => {
    if( !window ) {
      return;
    }

    window?.addEventListener('blur', blur );
    window?.addEventListener('focus', focus );
    window?.addEventListener('keydown', keydown );

    return () => {
      window?.removeEventListener('blur', blur);
      window?.removeEventListener('focus', focus );
      window?.removeEventListener('keydown', keydown );  
    }
  }, [blur,focus,keydown]);

  useEffect(() => {
    showVideoModal ? video?.current?.play() : video?.current?.pause();
  }, [showVideoModal]);

  if( !link && !list?.length ) {
    return <></>;
  }


  return (  
    <>
      <div>
        <button 
          onClick={ () => setShowVideoModal(true) }
          className="w-full flex justify-between button pl-2 pr-3 pt-[11px] pb-2 rounded-lg 
                  bg-theme-grey text-theme-blue hover:text-theme-blue/80 hover:bg-theme-grey/80
                    text-xs font-semibold leading-tight">
          <span>Watch Tutorial Video</span>
          <span><Icon name="play" /></span>
        </button>
      </div>

      { <div ref={modal}
          className={ `fixed top-0 left-0 w-full h-full bg-black/80 z-50` + ( showVideoModal ? '' : ' hidden' ) }
          onClick={(e) => {
            if( e.target === modal.current ) {
              setShowVideoModal( false )
            }
          }}
          >
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-2 rounded">
            <button onClick={ () => setShowVideoModal(false) }
              className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 bg-white rounded-full leading-none p-1 text-theme-blue hover:text-black">
              <Icon name="circle-xmark" />
            </button>
            <video ref={video} 
              controls preload="metadata"
              className="max-w-full max-h-full">
              { link && <source src={ link } /> }

              { list?.map( item => item?.src ? <source src={ item?.src } type={ item?.type} /> : <></> )}
            </video>
          </div>
        </div>
      }
    </>
  );
}

export default VideoButton;