import { useEffect, useRef, useState } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import DraggableHeader from "./DraggableHeader";
import ColumnConfig from "./ColumnConfig";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const columnHelper = createColumnHelper()

const DataTable = ( props ) => {
  const {
    fields = ['population'], 
    limit = 4000, 
    location = null,
    level,
    mapBounds,
    indexVariables,
    updateMap,
    setMapField,
    removeField,
    onFipsSelected,
    lockedFips,
    onFipsLocked,
    
    getFips,
    field,
    fips,
    allFields,
    
    sort,
    setSort,
    queryFilter,
    threshold
  } = props;

  const container = useRef( null );
  const [entries,setEntries] = useState([]);
  const [loading,setLoading] = useState( false );
  const [aborter,setAborter] = useState(null);
  const [page,setPage] = useState( props.page || 1 );
  const [total,setTotal] = useState(0)
  const [loadedFields,setLoadedFields] = useState([]);

  const [fipsSelected, setFipsSelected] = useState( null );
  const [requestedUrl, setRequestedUrl] = useState( null );
  const [tblScrollTo, setTblScrollTo] = useState( null );

  const [requestTimeout, setRequestTimeout] = useState( [] );

  const [order,setOrder] = useState( ['_row_index', ...fields.map( f => typeof f === 'string' ? f : f.name )] );

  const styles = {
    fipsCode: 'hidden text-left',
    fipsLabel: 'text-left'
  }

  function load( forced = false ) {
    if( requestTimeout?.length ) {
      let to;
      while( to = requestTimeout.shift() ) {
        // console.log( 'clear getHash TO', to );
        clearTimeout( to );
      }
    }

    let to = setTimeout( () => {
      // console.log( "doing getHash", to );
      _load( forced );
    }, 750 );

    requestTimeout.push( to );

    setRequestTimeout( JSON.parse( JSON.stringify( requestTimeout ) ) );
  }

  function _load( forced = false ){
    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'stats';
    
    const form = new FormData;

    if( location ) {
      form.append('location', location);
    }
    if( level ) {
      form.append('level', level);
    }
    
    let names = [];
    if( fields && fields.length ) {
      names = fields.map( f => {
        const k = typeof f == 'object' ? f.name : f;

        if( !['fipsCode','fipsLabel'].includes(k) ) {
          return k;
        }

        return null;
      }).filter( f => f !== null );

      form.append('fields', names.join(',') );
    }

    if( page ) {
      form.append('page', page);
    }

    if( mapBounds && !location ) {
      form.append('bounds', mapBounds );
    }

    if( queryFilter ) {
      const where = JSON.parse( JSON.stringify(queryFilter) );
      where.conditions = where.conditions.filter(c => !!c.field ).map( c => {
        const field = allFields.find( f => f.id === +c.field );
        
        if( field.name.match( /^_idx/ )  ) {
          return { ...c, field: indexVariables.find( v => v.name === field.name )}
        }

        return { ...c };
      })

      form.append('where', JSON.stringify( where ) );
    }

    if( indexVariables?.length ) {
      const used = indexVariables.filter( v => names.includes( v.name ) );

      form.append('idx', JSON.stringify( used ) );
    }

    // console.log( requestedUrl, url.href, requestedUrl == url.href, mapBounds, location );
    const fullUrl =  url.href + new URLSearchParams( form ).toString();
    if( requestedUrl && requestedUrl == fullUrl && !forced ) {
      return;
    }

    if( loading && aborter ) {
      aborter.abort();
    }    

    url.searchParams.append('t', +(new Date) );

    const ac = new AbortController();
    const signal = ac.signal;

    setLoading( true );
    setEntries( [] );
    setLoadedFields( fields );

    fetch( url.href, { method: "POST", body: form, signal })
    .then( response => response.json() )
    .then( result => {
      if( !result.status ) {
        return failed( result );
      }

      setRequestedUrl( fullUrl );
      // setHash( result.uid );

      if( result.stats ) {
        setTotal( result.total );

        const { asObject, asArray, fipsShown, fipsHidden } = processEntries( result.stats, result );

        // console.log(  asObject, asArray, fipsShown, fipsHidden );
        // console.log( 'hidden', fipsHidden );

        if( updateMap ) {
          updateMap( { ...result, entries: asObject, fipsSelected: fipsSelected, fipsShown, fipsHidden } );
        }

        // check is selected fips are present in new entries
        if( lockedFips.length ) {
          const included = [];
          const list = asArray;
          for( let i = 0; i < lockedFips.length; i++ ) {
            const fips_code = lockedFips[i];
            if( list.find( e => e.fips_code == fips_code ) ) {
              included.push( fips_code );
            }
          }
          updateLocked( included );
        }
        
      }
    })
    .catch( failed )
    .finally(() => {
      setLoading( false );
    });

    setAborter( ac );


    function failed(){

    }
  } // load

  function processEntries( data, result ){

    const entries = {};

    data.forEach( item => {
      if( !item ) {
        return;
      }
      
      if( typeof entries[ item.fips_code ] == "undefined" ) {
        const fips = getFips( item.fips_code );

        entries[ item.fips_code ] = {
          fips_code: item.fips_code,
          fips_label: item.fips_label || fips.label,
          fips: fips
        };
      }

      const _field = allFields.find( f => f.id == item.field_id );

      if( _field ) {        
        switch( _field.unit ) {
          case "%":
            item.value_txt = parseFloat( Math.round( item.value * 10000 ) / 100 ).toFixed(2) + '%';
            break;

          case "$":
            item.value_txt = new Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format( item.value );
            break;

          default:
            let value = item.value;

            switch( _field.step ) {
              case 0.1: value = parseFloat( value ).toFixed(1); break;
              case 0.01: 
              value = parseFloat( value ).toFixed(2); 
              break;
            }

            if( _field.unit ) {
              item.value_txt = value + _field.unit;
            }
            else if( /^[-]{0,1}\d+$/.test( value ) ){
              item.value_txt = new Intl.NumberFormat( 'en-US', {}).format( value );
            }
            else if( /^[-]{0,1}\d+(\.\d+)$/.test( value ) ){
              item.value_txt = new Intl.NumberFormat( 'en-US', { 
                                      minimumFractionDigits: 2, 
                                      maximumFractionDigits: 2 
                                  }).format( value );
            }
            else {
              item.value_txt = item.value;
            }
        }

        entries[ item.fips_code ][ _field.name ] = item;
      }
      else {
        console.log( "No field for ", item.field_id, JSON.stringify( _field ) );
        item.value_txt = item.value;
      }
    });

    if( result.idx ) {
      for( let k in result.idx ) {
        // const theIdxVar = indexVariables.find( v => v.name == k );

        for( let fips_code in result.idx[k].data ) {
          if( typeof entries[ fips_code ] === 'undefined' ) {
            // skip
            continue;
          }

          const value = result.idx[k].data[ fips_code ];

          entries[ fips_code ][ k ] = {
            field_name: k,
            field_label: indexVariables.find( v => v.name == k )?.label,
            value: value,
            value_txt: new Intl.NumberFormat( 'en-US', { 
                          minimumFractionDigits: 2, 
                          maximumFractionDigits: 2 
                      }).format( value )
            
            // Math.round( value * 100 ) / 100,
          }
        }
      }
    }

    // console.log( entries );    
    let dataTableRows = Object.values( entries );
    const asArray = sortEntries( dataTableRows );

    const allFips = Object.keys( entries ),
          shown = asArray.map( it => it.fips_code ),
          hidden = allFips.filter( it => !shown.includes( +it.fips_code ) );
          

    return { asObject: entries, asArray, fipsShown: shown, fipsHidden: hidden };
  } // processEntries

  function updateFipsSelected( fips_code ){
    setFipsSelected( fips_code );
    onFipsSelected( fips_code );
  }

  function updateLocked( list ){
    onFipsLocked( list );
  }

  function cn() {

    const names = [];

    for( let i = 0; i < arguments.length; i++ ) {
      const obj = arguments[i];

      switch( typeof obj ) {
        case 'object': 
          if( Array.isArray(obj) ) {
            for( let i = 0; i < obj.length; i++ ) {
              names.push( cn( obj[i]) );
            }
          }
          else {
            for( let name in obj ) {
              if( obj[name] ) {
                names.push( name );
              }
            }
          }
          break;
        case 'string':
          names.push( obj );
          break;
      }
    }
    

    return names.join(' ');
  } // cn

  function cellCn( fieldName ) {
    const classNames = [];
    if( fieldName === field ) {
      classNames.push( 'is-map-field' );
    }

    if( typeof styles[fieldName] != "undefined" ) {
      classNames.push( styles[fieldName] );
    }

    return classNames.join( " " );
  } // cellCn

  function filterThreshold( entries ){
    let list = entries;

    if( threshold ){
      const totalPopulation = entries.reduce( (acc,entr) => {
        return acc + (+entr.population.value);
      }, 0);

      const populationThreshold = Math.round( totalPopulation * threshold / 100 );

      console.log( "Total Population: " + totalPopulation +  ",  Threshold: " + populationThreshold );

      let pop = 0;
      list = entries.filter( entr => {
        if( pop > populationThreshold ) {
          return false;
        }

        pop += entr.population.value;
        return true;
      });
    }

    return list;
  } // filterThreshold

  function sortEntries( _inList = entries ){
    let _sort = JSON.parse( JSON.stringify( sort ) );

    if( !_inList || !_inList.length ) {
      // console.log( "empty list", _inList );
      return [];
    }

    if( !_sort?.length ) {
      _sort = [{
        field: 1,
        sort: 'DESC'
      }];
    }

    try {
      let list = JSON.parse( JSON.stringify( _inList) );
      let prevSN = null;

      for( let i = 0; i < _sort.length; i++ ) {
        const s = _sort[i];

        const direction = s.sort === 'ASC';
        const sortField = allFields.find( f => f.id === +s.field );

        if( !sortField ) {
          console.log( "Can't sort by field - not found" );
          continue;
        }

        if( !fields.find( f => ( typeof f === 'string' && f === sortField.name ) || ( f.name === sortField.name ) ) ) {
          console.log( `Exclude field ${ sortField.name } from sorting` );
          continue;
        }

        const sn = sortField.name;

        list = list.sort( (a,b) => {
          if( prevSN && a[prevSN] && b[prevSN] && a[prevSN].value !== b[prevSN].value ) {
            return 0;
          }

          // console.log( sn, a[sn], b[sn] );
          if( a[sn] && b[sn] ) {
            if( a[sn].value > b[sn].value ) {
              return direction ? 1 : -1;
            }

            if( a[sn].value < b[sn].value ) {
              return direction ? -1 : 1;
            }
          }

          return 0;
        });

        prevSN = sn;
      }
     
      const filteredList = filterThreshold( list );

      filteredList.forEach( (r,i) => { r._row_index = i + 1 } );

      setEntries( filteredList );

      return filteredList;
      // }
    }
    catch( error ) {
      console.log( 'sort error', error );
      return false;
    }
    
  } // sortEntries

  function scrollTable(){
    // don't scroll if we have selected rows
    if( lockedFips.length ) {
      return;
    }

    // find required table row
    // check if it's our of view
    // scroll to it
    const row = container?.current?.querySelector('tr.is-map-fips');
  
    if( !row ) {
      return;
    }

    const containerHeight = container.current.getBoundingClientRect().height,
          currentScrollTop = container.current.scrollTop,
          currentScrollLeft = container.current.scrollLeft,
          offsetTop = row?.offsetTop;

    if( offsetTop > currentScrollTop + containerHeight || offsetTop < currentScrollTop ) {
      container.current.scrollTo({ left: currentScrollLeft, top: offsetTop - containerHeight / 2, behavior: 'smooth' });
    }
  } // scrollTable

  function reoder(item, newIndex, table) {

    const newOrder = [...order];

    const indexDelta = newOrder.indexOf( item.id ) - item.index,
          currentIndex = item.index + indexDelta,
          newOrderIndex = newIndex + indexDelta;

    const [removedColumn] = newOrder.splice(currentIndex, 1);

    newOrder.splice(newOrderIndex, 0, removedColumn);

    setOrder(newOrder);
  } // reorder

  useEffect( () => {
    load();
  }, [location,level,page,mapBounds,queryFilter,indexVariables]);

  useEffect( () => {
    
    if( threshold ) {
      load( true );
    }
    else {
      // console.log( "sort changed", "threshold", threshold, sort );    
      sortEntries();
    }
  }, [sort]);

  useEffect( () => {
    
    let shouldReload = false;
    const newOrder = [...order];

    const fieldsList = [];

    for( let i = 0; i < fields.length; i++ ) {
      const k = typeof fields[i] == 'object' ? fields[i].name : fields[i];

      fieldsList.push( k );

      if( !['fipsCode','fipsLabel'].includes( k ) 
          && !k.match( /#all/ )
          && !loadedFields.includes( k ) ) {
        // console.log( 'should reload because of ', k);
        shouldReload = true;
      }

      if( !order.includes(k) ) {
        newOrder.push(k);
      }
    }

    if( shouldReload && !loading ) {
      load();
    }

    setOrder( newOrder.filter( f => fieldsList.includes(f) ) );

  }, [fields]);

  useEffect( () => {
    if( !container?.current || loading ) {
      // console.log( "don't scroll", container?.current, loading );
      return;
    }

    if( tblScrollTo ) {
      clearTimeout( tblScrollTo );
    }

    setTblScrollTo( setTimeout( scrollTable, 500 ) );   
  }, [loading,fips]);

  useEffect( () => {
    table?.setColumnOrder( order );
  }, [order]);

  const table = useReactTable({
    data: entries,
    columns: 
    [
      /* columnHelper.accessor( '_row_index', {
        header: () => "#",
        accessorFn: (row) => {
          return row._row_index;
        },
        disableReorder: true,
        size: 20
      }),*/ 
      ...(
        fields
        .filter( fieldsItem => ( typeof fieldsItem == 'string' ? fieldsItem : fieldsItem?.name ) !== 'fipsCode' )
        .map( fieldsItem => {
          const k =  typeof fieldsItem == 'string' ? fieldsItem : fieldsItem?.name;
          const labelFb = typeof fieldsItem == 'string' ? fieldsItem : fieldsItem?.label;
          const _field = allFields.find( _f => _f.name === k );

          const data = {
            id: k,
            header: () => _field ? ( _field?.label ?? k ) : ( labelFb || k ),
            show: k !== 'fipsCode',
            disableReorder: ['fipsCode','fipsLabel'].includes( k ),
            size: k === 'fipsLabel' ? 150 : 100
          };

          if( fieldsItem?.render ) {
            data.accessorFn = ( row ) => {
              return fieldsItem.render( row );
            }
          }

          return columnHelper.accessor( k, data );
        })
      )
    ],
    getCoreRowModel: getCoreRowModel(),

    initialState: {
      columnOrder: ['_row_index', ...fields.map( f => typeof f === 'string' ? f : f.name )]
    },

    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
  });
  

  if( loading ) {
    return <div>Loading...</div>
  }

  const pages = Math.ceil( total / limit );
  function pagination( pages, page ) {
    const items = [];

    for( let i = 1; i <= pages; i++ ) {
      items.push( <button key={i} onClick={ (event) => setPage(i) }>{ i }</button> );
    }

    return items;
  }

  const tableOffset = container?.current?.getBoundingClientRect().top ?? 0;

  if( loading || !allFields.length ) {
    return <div>Loading...</div>
  }
  else if( entries.length ) {
    let list = entries;

    return (
      <div className="data-table-container scrollbar" ref={container} 
        style={{ height: `calc( 100vh - ${tableOffset}px - 1rem )` }}>

        <table  className="data-table"
                style={{
                  width: table.getCenterTotalSize(),
                }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <DndProvider backend={HTML5Backend} key={headerGroup.id}>
                <tr className="--headers-row">
                  {headerGroup.headers.map( (header,i) => {
                    const columnDef = header.column.columnDef,
                          accessorKey = columnDef.accessorKey,
                          _field = allFields.find( _f => _f.name === accessorKey );
                    
                    const resizer = (
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `column-resizer ${
                            table.options.columnResizeDirection
                          } ${
                            header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                        }}
                        className="w-1 h-9 hover:bg-white cursor-col-resize"
                      ></div>
                    );

                    return (
                    ( header.isPlaceholder || header.column.columnDef.disableReorder )
                      ? <th key={header.id} 
                            className={ cellCn( accessorKey ) }
                            style={{ 
                              width: header.getSize(),
                            }}                            
                            >

                          <div className="flex justify-between items-center gap-2">
                            { header.column.columnDef.header() }

                            { resizer }
                          </div>
                        </th>
                      : 
                      <DraggableHeader
                        reoder={(item,newIndex) => reoder(item,newIndex,table)}
                        key={header.id}
                        column={header}
                        index={i}
                        cellCn={cellCn}
                        setMapField={setMapField}
                        columnConfig={
                          <ColumnConfig 
                            field={_field}
                            sort={sort}
                            remove={() => removeField( accessorKey ) }
                            setSort={( dir ) => {
                              const newSort = JSON.parse( JSON.stringify(sort) );
                              console.log( _field, dir );
                              // check if this field is in sort already                                  
                              const isIn = newSort.findIndex( s => s.field == _field.id )

                              if( -1 !== isIn ) {
                                // remove from whatever position it's in 
                                newSort.splice( isIn, 1 );
                              }

                              // add it to the top, 
                              // as first sorting field
                              newSort.unshift({
                                field: _field.id,
                                sort: dir
                              });

                              setSort( newSort );
                            }}
                          />
                        }
                        resizer={ resizer }
                      /> 
                    )}
                  )}
                </tr>
              </DndProvider>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}
                className={ cn({
                  'is-map-fips': fips === row.original.fips_code,
                  'is-selected-fips': lockedFips.includes( row.original.fips_code )
                }) }
                
                onMouseOver={ () => { 
                  updateFipsSelected( row.original.fips_code ) 
                } }

                onClick={ () => {
                  const newLocked = [...lockedFips];
                  if( newLocked.includes( row.original.fips_code ) ) {
                    newLocked.splice( newLocked.indexOf( row.original.fips_code ), 1 );
                  }
                  else {
                    newLocked.push( row.original.fips_code );
                  }

                  updateLocked( newLocked );
                }}              
                >
                {row.getVisibleCells().map(cell => {
                  const accessorKey = cell.column.columnDef.accessorKey;
                  const cellValue = cell.getValue();

                  return (
                  <td key={cell.id} 
                    className={ cellCn( accessorKey )}>
                    { typeof cellValue === 'object' ? cellValue?.value_txt ?? cellValue?.value : cellValue }
                  </td>
                  )}
                )}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination flex items-center justify-center gap-2">
          
        </div>
      </div>
    );
  }
  else {
    return (
      <div>
        <br />
        <div>Nothing found</div>
      </div>
    )
  }


}

export default DataTable;