import { useState, useEffect } from "react";

import Icon from "./Icon";

const DataFilter = ({
    onSet,
    fields,
    allFields
  }) => {

  const [open, setOpen] = useState( false );
  const [filter,setFilter] = useState( localStorage?.getItem('dataFilter') ? JSON.parse( localStorage?.getItem('dataFilter') ) : { operator: "AND", conditions: [{field: "", operator: ">", value: ""}] } );
  const [backup,setBackup] = useState( null )
  const [type,setType] = useState( localStorage?.getItem('dataFilterType') ?? 'select' )
  const [population, setPopulation] = useState( localStorage?.getItem('population') ?? 10 );

  const toggle = () => {
    if( open ) {
      _setFilter( backup );
    }
    else {
      setBackup( filter );
    }

    setOpen( !open );
  }; // toggle
  
  const _setFilter = ( newfilter ) => {

    setFilter( newfilter );    
  }; // _setFilter

  const update = ( index, param, value ) => {
    const newfilter = JSON.parse( JSON.stringify( filter ) );
    newfilter.conditions[ index ][ param ] = value;
    _setFilter( newfilter );
  }; // update

  const remove = ( index ) => {
    const newfilter = JSON.parse( JSON.stringify( filter ) );
    if( newfilter.conditions.length === 1 ) {
      newfilter.conditions[0].field = "";
      newfilter.conditions[0].operator = ">";
      newfilter.conditions[0].value = "";
    }
    else {

      newfilter.conditions.splice( index, 1 );
    }
    _setFilter( newfilter );
  }; // remove

  const add = () => {
    const newfilter = JSON.parse( JSON.stringify( filter ) );
    newfilter.conditions.push({field: "", operator: ">", value: ""});
    _setFilter( newfilter );
  }; // add

  const clearFilter = () => {
    const newfilter = { operator: "AND", conditions: [{field: "", operator: ">", value: ""}] };
    _setFilter( newfilter );
    localStorage?.setItem( 'dataFilter', JSON.stringify( newfilter ) );
    setOpen( false );
    onSet( 'select', newfilter );
  }; // clearFilter

  const apply = () => {
    setBackup( filter );
    localStorage?.setItem( 'dataFilter', JSON.stringify( filter ) );
    setOpen( false );
    triggerOnSet();
  }; // apply

  const triggerOnSet = () => {
    // console.log( "Data filter onSet", type, filter, population );
    if( type == 'select' ) {
      onSet( type, filter );
    }
    else {
      onSet( type, population );
    }
  }; // triggerOnSet

  const changeType = ( val ) => {
    localStorage?.setItem( 'dataFilterType', val );
    setType( val );
  }; // changeType

  const changePopulation = ( val ) => {
    localStorage?.setItem( 'dataFilterPopulation', val );
    setPopulation( val );
  }; // changePopulation

  useEffect( () => {
    triggerOnSet();
  }, []);

  const hasFilter = ( type == 'select' && !!filter?.conditions?.filter( f => f.field && f.value ).length ) 
                    || ( type == 'population' && population < 100 );

  return (
    <div className="relative">
      <button className={ `flex gap-2 items-center px-2 relative ${open && 'bg-gradient-to-t from-gray-200 via-gray-50'}` } 
        onClick={ toggle }>
        <Icon name='filter'/>
        <span>Filter</span>

        { !open && hasFilter && <span className="w-[3px] h-[3px] absolute top-[20%] right-0 border border-red-600 rounded-full 
                                                  animate-pulse"></span> }
      </button>

      { 
        open && fields?.length && allFields?.length && (
          <div className="absolute left-1/2 bottom-0 z-10
                          -translate-x-1/2 translate-y-full 
                          p-4 min-w-[20rem]
                          bg-white border border-solid border-gray-400 shadow
                          flex flex-col gap-4">
            <div className="flex gap-3 items-center justify-between
              border-b border-solid border-gray-400
              -mx-4 px-4 pb-4
              ">
              <div>Create&nbsp;Filter</div>

              <div className="flex gap-4 items-center">
                <button className="flex gap-2 items-center"
                  onClick={ () => changeType('select') }>
                  <Icon name={ type === 'select' ? 'circle-dot' : 'circle' } />
                  <span>Select</span>
                </button>

                <button className="flex gap-2 items-center"
                  onClick={ () => changeType('population') }>
                  <Icon name={ type === 'population' ? 'circle-dot' : 'circle' } />
                  <span>Population</span>
                </button>                
              </div>
            </div>

            { type == 'select' && <div>
              { filter.conditions.length > 1 && <div className="flex gap-2">
                  <span>Conditions operator</span>
                  <select onChange={ (e) => {
                      const fltr = JSON.parse( JSON.stringify( filter ) );
                      fltr.operator = e.target.value;
                      _setFilter( fltr );
                    } }>
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                  </select>
                </div> 
              }

              <div className="flex flex-col gap-6">
                { filter.conditions.map( (c,i) => (
                    <div className="flex flex-col gap-2 items-start" key={ i }>

                      <div className="flex gap-2 items-center w-full">
                        <select value={ c.field } 
                          className={ c.field ? 'w-full appearance-none border-none font-semibold' : 'w-full' }
                          onChange={ (e) => { update( i, 'field', e.target.value ); e.target.blur(); }}>
                          <option key="" value="">Field</option>
                          { 
                            fields.map( _field => {
                              const fn = _field?.name ?? _field;

                              const field = allFields.find( f => f.name === fn );

                              if( !fn || !field ) {
                                return null;
                              }

                              return (
                                <option key={ fn } 
                                      value={ field.id } 
                                      checked={ field.id === c.field }>
                                  { field?.label || fn }
                                </option>
                              )
                            })
                          }
                        </select>
                      </div>

                      <div className="flex gap-1 items-center">
                        <div className="flex gap-2 items-center">
                          <select value={ c.operator }
                            onChange={ (e) => { update( i, 'operator', e.target.value ) } }
                            >
                            <option value="">Operator</option>
                            { [
                                {value: '=', label: 'equals'},
                                {value: '!=', label: 'not equals'},
                                {value: '>', label: 'is greater than'},
                                {value: '>=', label: 'is equal or greater than'},
                                {value: '<', label: 'is less than'},
                                {value: '<=', label: 'is equal or less than'},
                              ].map( o => <option key={ o.value } value={o.value} checked={ c.operator === o.value }>{ o.label }</option> ) 
                            }
                          </select>
                        </div>   

                        <div className="">
                          <input type="text" value={ c.value } 
                            className="pl-1 w-16"
                            placeholder="Value"
                            onChange={ (e) => { update( i, 'value', e.target.value )}} />
                        </div>        

                        <button className="text-xs text-gray-400"
                          onClick={ () => remove(i) }>
                          <Icon name="xmark" />
                        </button>
                      </div>
                    </div>
                  ))
                }          
              </div>
            </div> }

            { type == 'population' && <div>
              <div className="leading-normal">
                Based on the current sort order of the table, <br />
                select locations that equal:&nbsp; 
                <span className="inline-block border border-slate-400 px-2 py-1">
                  <input 
                    value={ population }
                    onChange={ (e) => changePopulation( e.target.value ) } 
                    className="apperance-none border-none w-8 text-center"
                    />
                    <span>%</span>
                </span>
              </div>
            </div>}

            <div className="flex gap-8 justify-between">
                <span>
                  { type == 'select' && 
                    <button className="button button-success"
                      onClick={ (e) => add() }>
                      <Icon name="plus" />
                    </button>
                  }
                </span>

                <div className="flex gap-2">
                  <button className="button" onClick={ toggle }>Cancel</button>
<button className="button button-danger" onClick={ clearFilter }>Clear</button>
                  <button className="button button-primary" onClick={ apply }>Apply</button>
                </div>
              </div>  
          </div>
        )
      }
    </div>
  );
}

export default DataFilter