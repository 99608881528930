import React, { useState, useEffect, useRef } from "react";

import Icon from "./components/Icon";

import DataTable from "./components/DataTable";
import MapBoxWrapper from "./components/MapBoxWrapper";

import DataSort from "./components/DataSort";
import DataFilter from "./components/DataFilter";

import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import MapGenerator from "@watergis/mapbox-gl-export/dist/map-generator";
import "../node_modules/@watergis/mapbox-gl-export/css/styles.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import { accessToken, Map as MapboxMap } from '!mapbox-gl';
import VideoButton from "./components/VideoButton";

// override @watergis map generator in order to add legend to the image
MapGenerator.prototype.generate = function( onDone = () => {} ){
  const this_ = this

  // Calculate pixel ratio
  const actualPixelRatio = window.devicePixelRatio
  Object.defineProperty(window, "devicePixelRatio", {
    get() {
      return this_.dpi / 96
    }
  })
  // Create map container
  const hidden = document.createElement("div")
  hidden.className = "hidden-map"
  document.body.appendChild(hidden)
  const container = document.createElement("div")

  const mapBoundingBox = this.map.getContainer().getBoundingClientRect();

  let zoom = Math.round( this.map.getZoom() ),
      maxZoom = this.map.getMaxZoom();
  
  // https://docs.mapbox.com/help/glossary/zoom-level/
  const zoomLevels = [ 59959.436, 29979.718, 14989.859, 7494.929, 3747.465, 1873.732, 936.866, 468.433, 234.217, 117.108, 58.554, 29.277, 14.639, 7.319, 3.660, 1.830, 0.915, 0.457, 0.229, 0.114, 0.057, 0.029, 0.014 ];

  let scale = zoomLevels[ zoom ] / zoomLevels[ maxZoom ];
  if( mapBoundingBox.width * scale > 4096 || mapBoundingBox.height * scale > 4096 ) {
    scale = Math.min( 4096 / mapBoundingBox.width, 4096 / mapBoundingBox.height );
  }

  if( scale > 3 ) {
    scale = 3;
  }
  
  container.style.width = mapBoundingBox.width * scale + 'px'; // this.toPixels( this.width )
  container.style.height =  mapBoundingBox.height * scale + 'px'; // this.toPixels( this.height )
  hidden.appendChild(container)
  
  const style = this.map.getStyle()
  if (style && style.sources) {
    const sources = style.sources
    Object.keys(sources).forEach(name => {
      const src = sources[name]
      Object.keys(src).forEach(key => {
        // delete properties if value is undefined.
        // for instance, raster-dem might has undefined value in "url" and "bounds"
        if (!src[key]) delete src[key]
      })
    })
  }
  
  // Render map
  const renderMap = new MapboxMap({
    accessToken: this.accesstoken || accessToken,
    container,
    style,
    center: this.map.getCenter(),
    zoom: this.map.getZoom(),
    bearing: this.map.getBearing(),
    pitch: this.map.getPitch(),
    interactive: false,
    preserveDrawingBuffer: true,
    fadeDuration: 0,
    attributionControl: false,
    // hack to read transfrom request callback function
    transformRequest: this.map._requestManager._transformRequestFn
  })

  renderMap.fitBounds( this.map.getBounds() );
  
  // @ts-ignore
  const images = (this.map.style.imageManager || {}).images || []
  Object.keys(images).forEach(key => {
    renderMap.addImage(key, images[key].data)
  })
  
  renderMap.once("idle", () => {
    const canvas = renderMap.getCanvas()

    const img = new Image();
    img.onload = () => {
      html2canvas( document.getElementById('map-legend'), { backgroundColor: '#FFFFFF', scale } )
      .then( legendCanvas => {
        const { width, height } = canvas.getBoundingClientRect();
        const newCanvas = document.createElement('canvas');
        newCanvas.setAttribute('width', width);
        newCanvas.setAttribute('height', height);

        const ctx = newCanvas.getContext('2d');
        ctx.drawImage( img, 0, 0, width, height );

        const sw = legendCanvas.width, 
              sh = legendCanvas.height;

        ctx.drawImage( legendCanvas, width - sw - 5, height - sh - 5, sw, sh );

        const fileName = `mcje-map.${this_.format}`;
        this_.toPNG(newCanvas, fileName);
      
        renderMap.remove()
        hidden.parentNode?.removeChild(hidden)
        Object.defineProperty(window, "devicePixelRatio", {
          get() {
            return actualPixelRatio
          }
        });

        onDone();
      });
      
    };
    img.src = canvas.toDataURL();    

  });
}

function App() {
  const [configLoading,setConfigLoading] = useState( false );
  const [configLoaded,setConfigLoaded] = useState( false );

  const [config,setConfig] = useState( null );

  const [map,setMap] = useState( 1 ); // 0 - google maps, 1 - mapbox
  const [mapBounds,setMapBounds] = useState( null ); 

  // control whether left columns selector is shown
  const [columnsPanel,setColumnsPanel] = useState( true );
  
  // control what fields (columns) categories are open
  const [sidebarCategoriesOpen,
          setSidebarCategoriesOpen] = useState( JSON.parse( localStorage?.getItem('sidebarCategoriesOpen') ) ?? [] );

  // list of columns, added to the table
  const [columns,setColumns] = useState( JSON.parse( localStorage?.getItem('columns') ) ?? ['adm_1k'] );

  // what field is visualized on the map
  const [mapField,setMapField] = useState( localStorage?.getItem('mapField') ?? 'population'  );

  // list of FIPS codes, shown on the map
  const [mapFips,setMapFips] = useState( null );  

  // current map zoom
  const [mapZoom,setMapZoom] = useState( 8 ); 

  // mapbox object, is used for map export / download
  const [mapContainer,setMapContainer] = useState( null ); 

  // list of FIPS codes selected
  const [lockedFips,setLockedFips] = useState( [] );

  // full list of counties
  const [counties,setCounties] = useState([]);

  // full list of places
  const [places,setPlaces] = useState([]);

  // full list of categories
  const [categories,setCategories] = useState([]);

  // full list of fields
  const [fields,setFields] = useState([]);

  // processed data from the API
  const [statsData, setStatsData ] = useState([]);
  
  // list of fields to sort by
  const [tableSort,setTableSort] = useState( JSON.parse( localStorage?.getItem('dataSort') ) ?? [{"field": 1, "sort": 'DESC'}] ); 

  // list of fields to filter by 
  const [tableFilter,setTableFilter] = useState(null);

  // share of population to limit table display by
  const [populationThreshold,setPopulationThreshold] = useState(null);

  // level of data: county, tract, zip, place
  const [level,setLevel] = useState( localStorage?.getItem('level') ?? 'county' );

  // selected location
  const [location,setLocation] = useState( localStorage?.getItem('location') ?? '' );

  // whether to maks location or not
  const [maskLocation,setMaskLocation] = useState( localStorage?.getItem('maskLocation') ?? true );

  // search field
  const [search,setSearch] = useState( '' );
  
  // show / hide list of search results
  const [openLocationSuggestions,setOpenLocationSuggestions] = useState( false );

  // list of search results
  const [locationSuggestions,setLocationSuggestions] = useState( [] );

  // list of previous location searches
  const [locationHistory,setLocationHistory] = useState( localStorage?.getItem('locationHistory') ? JSON.parse( localStorage.getItem('locationHistory') ) : [] );

  // control for table width
  const [tableWidth,setTableWidth] = useState(50);

  // control for map width
  const [mapWidth,setMapWidth] = useState(50);

  // resizing status field
  const [isResizing,setIsResizing] = useState( false );

  // control whether to recenter map or not
  const [recenter,setRecenter] = useState( true );

  // indicate table export process
  const [doingTableExport,setDoingTableExport] = useState( false );

  // indicate map export process
  const [doingMapExport,setDoingMapExport] = useState( false );

  // holds current page url has code
  const [hash,setHash] = useState( window.location.pathname.replace( process.env.PUBLIC_URL, '' ).replace( /^\//, '') );

  // holds list of custom index variables 
  // and their composites
  const [indexVariables, setIndexVariables] = useState( localStorage?.getItem('indexVariables') ? JSON.parse( localStorage.getItem('indexVariables') ).map( iv => { if( !iv.id ) { iv.id = +(new Date); } return iv; }) : [] )

  // custom variable editor control
  const [idxVar,setIdxVar] = useState( null );

  // 
  const [hashBuildActive, setHashBuildActive] = useState( false );
  // 
  const [hashTimeout,setHashTimeout] = useState( [] );
  // hash load aborter
  const [aborter,setAborter] = useState( null );  

  const header = useRef(null);
  const resizeContainer = useRef(null);
  const resizer = useRef(null);

  const levels = {
    "tract": "Census Tracts",
    "zcta": "ZCTA",
    "place": "Places",
    "county": "County"
  }; 

  const defaultFields = [
    { name: 'fipsCode', label: "ID", render: (row) => row.fips_code },
    { name: 'fipsLabel', label: "Name", render: (row) => {
        if( row.fips_label ) {
          return row.fips_label;
        }

        const fips = getFips( row.fips_code );

        return fips?.label ?? row.fips_code;      
      } 
    },
    'population',
  ];
  
  // recursively builds list of menu items for the sidebar
  function buildSidebar( list, idx = 0, prefix = "" ) {
    const parent = prefix.replace(/-$/,'');
    const defaults = [...defaultFields.map( f => typeof f == 'object' ? f.name : f )];

    return (
      <ul data-parent={ parent } className={ cn({
        hidden: idx && !sidebarCategoriesOpen.includes( parent ),
        'pl-4 mt-2': !!idx,
        'flex flex-col gap-2': true,
        'top-level': !idx
      } ) }>
        { list.map( (level) => {
            const itemIcon = level.icon;
            const key = level.key.replace(/^cat_/,''),
                  isOpen = sidebarCategoriesOpen.includes( key );

            const isFinal = !(level.children && level.children.length) && idx;

            if( level.parent === 999999 ) {
              const isActive = columns.includes( key ) || defaults.includes( key );

              // display custom index var with pencil
              return (
                <li className={ cn({
                    'is-open': isOpen,
                    'top-level-item': !idx
                    }) 
                  }
                  data-key={ key }
                  key={ key }>

                  <div className="flex justify-between items-center gap-2 pr-2">

                    <button onClick={ () => isFinal ? ( idxVar ? addIdxVar( key ) : toggleColumn( key, level ) ) : toggleSidebarCategory( key ) }
                      className={ cn({
                        'active': columns.includes( key ) || defaults.includes(key),
                        'flex gap-2 items-center text-left': true
                      } ) }>
                      { itemIcon && <Icon name={ itemIcon } /> }
                      { level.label }
                    </button>
                  
                    
                    <span className="flex gap-2">
                      <button onClick={ () => {
                            toggleColumn( key.replace( /^(cat_)+/, '' ), level ) 
                          }
                        }
                        key={ key.replace( /^(cat_)+/, '' ) }
                        title={ "Add to table" }
                        className={ cn({
                          'nav-btn-inactive': !isActive,
                          'nav-btn-active': isActive,
                          'nav-btn aspect-square': true
                        } ) }>
                        <Icon name="hashtag" />
                      </button>                    

                      <button className="nav-btn bg-none hover:bg-gray-600 hover:text-white"
                        onClick={ () => {
                            const newIdxVar = indexVariables.find( v => v.name == key.replace('cat_','') );
                            // console.log( indexVariables, key )
                            setIdxVar(newIdxVar) 
                          }
                        }>
                          <Icon name="pencil" />
                      </button>
                    </span>
                    
                  </div>
                </li>
              );
            }
            else if ( idx < 2 || isFinal ) {
              // display category
              return (
                <li className={ cn({
                    'is-open': isOpen,
                    'top-level-item': !idx,
                    'has-children': !isFinal
                    }) 
                  }
                  data-key={ key }
                  key={ key }>

                  <button onClick={ () => isFinal ? ( idxVar ? addIdxVar( key ) : toggleColumn( key, level ) ) : toggleSidebarCategory( key ) }
                    className={ cn({
                      'active': columns.includes( key ) || defaults.includes( key ),
                      'flex gap-2 items-center': true
                    } ) }>
                    { itemIcon && <Icon name={ itemIcon } /> }
                    { level.label }
                  </button>

                  { level.children && level.children.length && buildSidebar( level.children, idx + 1, key + '-' ) }
                </li>
              );
            }
            else if( !isFinal ) {
              // display variable
              return (
                <li className={ cn({
                    'is-open': isOpen,
                    'top-level-item': !idx
                    }) 
                  }
                  data-key={ key }
                  key={ key }>
                  <div className="flex justify-between items-center gap-2 pr-2">
                    <span className="text-black/70">{ level.label }</span>

                    <span className="flex gap-2 items-center">
                      { level.children && level.children.length && level.children.map( (c) => {
                        if( c.key.match( /\#all$/ ) ) {
                          return null;
                        }

                        let bIcon;
                        if( c.icon ) {
                          bIcon = <Icon name={ c.icon } />
                        }
                        else {
                          if( c.unit ) {
                            switch( c.unit ) {
                              case '$': bIcon = <Icon name="dollar-sign" />; break;
                              case '%': bIcon = <Icon name="percent" />; break;
                              default:
                                bIcon = <Icon name="hashtag" />;
                            }
                          }
                          else if( c.key.match( /_1k$/ ) ) {
                            bIcon = <Icon name="divide" />
                          }
                          else if( c.key.match( /_exp$/ ) ) {
                            bIcon = <Icon name="dollar-sign" />
                          }
                          else {
                            bIcon = <Icon name="hashtag" />;
                          }
                        }

                        const isActive = columns.includes( c.key ) || defaults.includes( key );

                        return (
                          <button onClick={ () => {
                            idxVar ? addIdxVar( c.key ) : toggleColumn( c.key, c )
                          }}
                            key={ c.key }
                            title={ c.label }
                            className={ cn({
                              'nav-btn-inactive': !isActive,
                              'nav-btn-active': isActive,
                              'nav-btn aspect-square': true
                            } ) }>
                            { bIcon }
                          </button>
                        )   
                      })}
                    </span>
                  </div>
                </li>
              );
            }  
          })
        }
      </ul>
    )
  } // buildSidebar 

  // helper function to construct className
  function cn() {

    const names = [];

    for( let i = 0; i < arguments.length; i++ ) {
      const obj = arguments[i];

      switch( typeof obj ) {
        case 'object': 
          if( Array.isArray(obj) ) {
            for( let i = 0; i < obj.length; i++ ) {
              names.push( cn( obj[i]) );
            }
          }
          else {
            for( let name in obj ) {
              if( obj[name] ) {
                names.push( name );
              }
            }
          }
          break;
        case 'string':
          names.push( obj );
          break;
      }
    }
    

    return names.join(' ');
  } // cn


  // toggle sidebar category
  function toggleSidebarCategory( key ) {
    const list = [...sidebarCategoriesOpen];

    if( list.includes( key ) ) {
      // remove
      list.splice( list.indexOf(key), 1 );
    }
    else {
      // add
      list.push( key );
    }

    setSidebarCategoriesOpen( list );

    if( localStorage ) {
      localStorage.setItem('sidebarCategoriesOpen', JSON.stringify(list) );
    }
  } // toggleSidebarCategory

  
  // toggle table column
  function toggleColumn( key, level ) {
    const defaults = [...defaultFields.map( f => typeof f == 'object' ? f.name : f )];
    let list = [...columns];

    if( level?.key.match( /#all/ ) ) {
      // means all in section
       
      console.log( 'Toggle all', level );
      const _fields = level.fields;

      const notSelected = _fields.filter( f => !columns.includes(f) ).length;
      
      for( let i = 0; i < _fields.length; i++ ) {
        if( notSelected ) {
          if( !list.includes(_fields[i]) ) {
            list.push( _fields[i] );
          }
        }
        else {
          if( list.includes(_fields[i]) ) {
            list.splice( list.indexOf( _fields[i] ), 1 );
          }
        }
      }
    }
    else {
      // means specific 
      if( list.includes(key) ) {
        list.splice( list.indexOf( key ), 1 );

        if( mapField == key ) {
          // change to a different one
          updateMapField( list[ list.length - 1 ] );
        }
      }
      else if ( !defaults.includes(key) ) {
        // remove all filter
        const parent = key.replace( /-*[a-zA-Z]+$/, '' );

        if( parent && list.includes( parent ) ) {
          list.splice( list.indexOf( parent ), 1 );
        }

        list.push( key );

        updateMapField( key );
      }  
    }
    

    setColumns( list );

    if( localStorage ) {
      localStorage.setItem('columns', JSON.stringify(list) );
    }
  } // toggleColumn

  // set field shown on the map
  function updateMapField( field ) {
    // check if field has info
    if( !fields.find( f => f.name == field )) {
      setMapField( 'population' );
      localStorage.setItem( 'mapField', 'population' );
      return;
    }

    setMapField( field );
    localStorage.setItem( 'mapField', field );
  } // updateMapField

  // load system configuration: fields, categories, counties...
  function loadConfig(){

    if( configLoading || configLoaded ) {
      return;
    }

    setConfigLoading( true );

    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'config';
    
    fetch( url.href )
    .then( response => response.json() )
    .then( result => {
      if( !result.status || !result.config ) {
        return failed( result );
      }

      if( result?.config?.config ) {
        const newConfig = {};
        result.config.config.forEach( row => {
          if( typeof newConfig[ row.name ] !== 'undefined' ) {
            if( !Array.isArray(newConfig[ row.name ]) ) {
              newConfig[ row.name ] = [ newConfig[ row.name ] ];
            }

            newConfig[ row.name ].push( row.value );
          }
          else {
            newConfig[ row.name ] = row.value;
          }
        })
        setConfig( newConfig );
      }

      if( result.config.counties ) {
        setCounties( result.config.counties );
      }

      if( result.config.places ) {
        setPlaces( result.config.places );
      }

      if( result.config.categories ) {
        // 
        const cats = JSON.parse( JSON.stringify( result.config.categories ) );
        cats.push({
          id: 999999,
          icon: 'layer-group',
          label: 'Index Variables',
          name: '_idx_vars',
          parent: null
        });

        if( indexVariables?.length ) {
          const placed = [];
          for( let i = 0; i < indexVariables.length; i++ ) {
            if( placed.includes( indexVariables[i].name ) ) {
              continue;
            }
            placed.push( indexVariables[i].name );

            const indexVariable = {
              id: 1000000 + i,
              icon: null,
              label: indexVariables[i].label,
              name: indexVariables[i].name,
              parent: 999999
            };

            cats.push( indexVariable );
          }
        }

        // console.log( '>> cats', cats );
        setCategories( cats );
      }

      if( result.config.fields ) {
        const configFields = JSON.parse( JSON.stringify( result.config.fields ) );
        const maxId = configFields.reduce( (prev,cur) => {
          if( prev && prev.id > cur.id ) {
            return prev.id;
          }
          return cur.id;
        })

        if( indexVariables?.length ) {
          for( let i = 0; i < indexVariables.length; i++ ) {
            const indexVariable = {
              id: maxId + i + 1,
              category_id: 1000000 + i,
              label: indexVariables[i].label,
              name: indexVariables[i].name,
              step: 0.01
            };

            configFields.push( indexVariable );
          }
        }

        // console.log( '>> fields', configFields );
        setFields( configFields );
      }

      setConfigLoading( false );
    })
    .catch( failed )
    .finally(() => {

    });

    function failed(){
      // console.log( arguments );
    }
  } // loadConfig

  // load configuration for an opened url hash
  function loadHashConfig(){
    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'hash-config';
    url.search = '?uid=' + encodeURIComponent(hash);
    
    fetch( url.href )
    .then( response => response.json() )
    .then( result => {
      if( !result.status || !result.data ) {
        return failed( result );
      } 

      const params = result?.data?.params;      

      if( params.blocks ) {
        for( let f in params.blocks ) {
          localStorage.setItem('blocks@' + f, JSON.stringify( params.blocks[f] ) );
        }
        setRecenter( true );
      }

      if( params.mapBounds ) {
        localStorage.setItem( 'mapBounds', JSON.stringify( params.mapBounds ) );
        setMapBounds( params.mapBounds );
      }

      if( params.columns ) {
        if( params.columns.includes('population') ) {
          params.columns.splice( params.columns.indexOf('population'), 1 );
        }

        localStorage.setItem( 'columns', JSON.stringify( params.columns ) );
        setColumns( params.columns );
      }

      if( params.mapField ) {
        localStorage.setItem( 'mapField', params.mapField );
        setMapField( params.mapField );
      }

      if( params.lockedFips ) {
        localStorage.setItem( 'lockedFips', JSON.stringify( params.lockedFips ) );
        setLockedFips( params.lockedFips )
      }

      if( params.tableSort ) {
        localStorage.setItem( 'dataSort', JSON.stringify( params.tableSort ) );
        setTableSort( params.tableSort )
      }

      if( params.tableFilter ) {
        const tf = JSON.parse( JSON.stringify( params.tableFilter ) );
        tf.conditions = tf.conditions.map( c => {
          if( c.field === null ) {
            c.field = "";
          }

          if( c.value === null ) {
            c.value = "";
          }

          return c;
        });
        
        localStorage.setItem( 'dataFilter', JSON.stringify( tf ) );
        setTableFilter( tf );
      }

      if( params.populationThreshold ) {
        localStorage.setItem( 'populationThreshold', JSON.stringify( params.populationThreshold ) );
        setPopulationThreshold( params.populationThreshold )
      }

      if( params.level ) {
        changeLevel( params.level );
      }

      // always change location, if it's empty = it's Michigan
      changeLocation( params.location ? +params.location : '' );

      if( params.tableWidth ) {
        setTableWidth( +params.tableWidth );
      }

      if( params.mapWidth ) {
        setMapWidth( +params.mapWidth );
      }      

      if( params.maskLocation ) {
        localStorage.setItem( 'maskLocation', params.maskLocation );        
        setMaskLocation( params.maskLocation )
      }

      if( params.indexVariables ) {
        localStorage.setItem( 'indexVariables', JSON.stringify( params.indexVariables ) );
        setIndexVariables( params.indexVariables );

        if( sidebarCategoriesOpen?.length && !sidebarCategoriesOpen.includes( '_idx_vars' ) ) {
          toggleSidebarCategory( '_idx_vars' );
        }

        const categoryMaxId = Math.round( +(new Date) / 1000 );
        const _categories = JSON.parse( JSON.stringify( categories.filter( c => c.parent !== 999999 ) ) ),
              _fields = JSON.parse( JSON.stringify( fields ) );

        for( let i = 0; i < params.indexVariables.length; i++ ) {
          const categoryVariable = {
            id: categoryMaxId + i + 1,
            icon: null,
            label: params.indexVariables[i].label,
            name: params.indexVariables[i].name,
            parent: 999999
          };
          _categories.push( categoryVariable );

          
          const fieldVariable = {
            id: params.indexVariables[i].id,
            category_id: categoryMaxId + i + 1,
            label: params.indexVariables[i].label,
            name: params.indexVariables[i].name,
            step: 0.01
          };         
          _fields.push( fieldVariable );
        }

        setCategories( _categories );
        setFields( _fields );
      }
      else {
        console.log( 'empty index variables', params );
      }
    })
    .catch( failed )
    .finally(() => {
      setHashBuildActive(true);
    });

    function failed(){
      // console.log( arguments );
    }
  } // loadHashConfig

  // set current data level
  function changeLevel( value ) {
    setLevel( value );

    if( localStorage ) {
      localStorage.setItem('level', value );
    }
  } // changeLevel

  // set shown location fips/zip code
  function changeLocation( value ) {

    const history = JSON.parse( JSON.stringify(locationHistory) );

    if( value ) {
      history.unshift( value );
    }
    if( history.length > 16 ) {
      history.splice(16);
    }

    const unique = [];
    history.forEach( loc => {
      if( !unique.includes( +loc ) ) {
        unique.push( +loc );
      }
    })

    setLocationHistory( unique );
    setLocation( value );

    if( localStorage ) {
      localStorage.setItem('location', value );
      localStorage.setItem('locationHistory', JSON.stringify( unique ) );
    }


  } // changeLocation 

  function getCategoryChildren( parent, all ){
    const parents = all.filter( i => i.parent === parent );
    parents.sort((a,b) => {
      const aPosition = +(a.position || a.id ),
            bPosition = +(b.position || b.id );

      if( aPosition < bPosition ) return -1;
      else if ( aPosition > bPosition ) return 1;

      return 0;
    });

    return parents.map( p => {
      const item = { ...p };

      const children = getCategoryChildren( p.id, all );

      const itemFields = fields.filter( f => f.category_id === p.id );
      if( itemFields.length ) {
        const all = {
          key: p.name + '#all',
          label: 'All',
          fields: []
        };

        // children.push( all );
        itemFields.forEach( f => {
          const c = { ...f };
          delete c.category;

          c.key = c.name;

          if( !c.label ) {
            c.label = c.name;
          }

          all.fields.push( c.name );

          children.push( c );
        });
      }

      if( children.length ) {
        item.key = 'cat_' + p.name;
        item.children = children;
      }
      else {
        item.key = p.name;
      }

      children.sort((a,b) => {
        const aPosition = +(a.position || a.id ),
              bPosition = +(b.position || b.id );
  
        if( aPosition < bPosition ) return -1;
        else if ( aPosition > bPosition ) return 1;
  
        return 0;
      });

      return item;
    })
  } // getCategoryChildren

  function resize(event){
    if( !isResizing || !resizer.current || !resizeContainer.current ) {
      return;
    }

    const rw = resizer.current.getBoundingClientRect().width,
          rc = resizeContainer.current.getBoundingClientRect(),
          x = event.clientX - rc.left,
          w = rc.width;

    setTableWidth( x / w * 100 );
    setMapWidth( ( w - x ) / w * 100 );   
  } // resize

  function startResizing(){
    setIsResizing( true )
  }

  function endResizing(){
    setIsResizing( false )
  }  

  function getFips( code ){
    return places.find( p => p.code == code ) || counties.find( c => c.code == code );
  }

  function globalClickListener( event ){
    
    // maybe close search suggestion
    if( openLocationSuggestions && !event.target.closest('.search-box') ) {
      setOpenLocationSuggestions( false )
    }
  }
  
  function addIdxVar( key ) {
    // console.log( 'fn addIdxVar', key );
    const newIdxVar = JSON.parse( JSON.stringify(idxVar) );
    if( newIdxVar.vars.length == 10 ) {
      alert("Current variables limit of 10 has been reached");
      return;
    }

    newIdxVar.vars.push({
      name: key,
      dir: 'high',
      weight: 1
    });

    setIdxVar( newIdxVar );
  }

  function removeIdxVar( key ) {
    const newIdxVar = JSON.parse( JSON.stringify(idxVar) );

    const idx = newIdxVar.vars.findIndex( f => f.name == key );
    newIdxVar.vars.splice( idx, 1 );

    setIdxVar( newIdxVar );
  }

  function updateIdxVar( variable){
    const newIdxVar = JSON.parse( JSON.stringify(idxVar) );

    const idx = newIdxVar.vars.findIndex( f => f.name == variable.name );
    newIdxVar.vars.splice( idx, 1, variable );

    setIdxVar( newIdxVar );
  }

  function exportTable(){
    setDoingTableExport( true );


    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    let sheetName = "mcje";
    let filename = "mcje-";
    if( location ) {
      let locationName = [...counties,...places].find( l => l.code == location )?.label ?? location;
      locationName = locationName.replace( /[\.,\s]/g, '-' );

      sheetName += '-' + locationName;

      filename += locationName + "-";
    }

    if( level ) {
      filename += `by-${level}-`;
    }

    filename += (new Date).toISOString();

    // Acquire Data (reference to the HTML table)
    const tbl = document.querySelector(".data-table");

    // Extract Data (create a workbook object from the table)
    const workbook = XLSX.utils.table_to_book(tbl, { sheet: sheetName });
    // const workbook = XLSX.utils.table_to_book(tbl);

    /*
    // Process Data (add a new row)
    const ws = workbook.Sheets[ sheetName ];
    const cols = tbl.querySelector('tbody tr').children.length;

    XLSX.utils.sheet_add_aoa(ws, [
      [{ t: 's', v: "Created "+new Date().toISOString() }]
    ], { origin: { c: cols, r: 1 } });
    */

    XLSX.writeFile(workbook, `${ filename }.xlsx`);

    setDoingTableExport( false );
  } // exportTable

  function exportMap(){
    setDoingMapExport( true );

    const mapGenerator = new MapGenerator(
      mapContainer,
      [297, 210],//pageSizeValue,
      300, // Number(dpiType.value),
      'png', //formatType.value,
      'mm', //Unit.mm,
      process.env.REACT_APP_MAPBOX_API_KEY // this.options.accessToken,
    );

    mapGenerator.generate( () => setDoingMapExport(false) );
  } // exportMap

  function getHash(){
    cleanSort();

    if( hashTimeout?.length ) {
      let to;
      while( to = hashTimeout.shift() ) {
        // console.log( 'clear getHash TO', to );
        clearTimeout( to );
      }
    }

    let to = setTimeout( () => {
      // console.log( "doing getHash", to );
      _getHash();
    }, 500 );

    hashTimeout.push( to );

    setHashTimeout( JSON.parse( JSON.stringify( hashTimeout ) ) );
  }

  function _getHash(){ 

    if( !hashBuildActive || isResizing ) {
      return;
    }

    const data = {
      mapBounds,
      columns,
      mapField,
      lockedFips,
      tableSort,
      tableFilter,
      populationThreshold,
      level,
      location,
      maskLocation,
      tableWidth,
      mapWidth,
      indexVariables
    };

    if( maskLocation ) {
      delete data.mapBounds;
    }

    // get blocks (fields) configuration
    data.blocks = {};
    for( let i = 0; i < columns.length; i++ ) {
      const ls = localStorage?.getItem('blocks@' + columns[i] + '/' + level );
      if( ls ) {
        data.blocks[ columns[i] + '/' + level ] = JSON.parse( ls );
      }
    }

    let url = new URL(process.env.REACT_APP_API_DOMAIN)
    url.pathname += 'hash';

    if( aborter ) {
      aborter.abort();
    }    

    const ac = new AbortController();
    const signal = ac.signal;

    fetch( url.href, { 
      headers: [
        ['Content-Type', 'application/json']
      ],
      method: "POST", 
      signal,
      body: JSON.stringify( { data } )
    })
    .then( response => response.json() )
    .then( result => {
      if( !result.status ) {
        return failed( result );
      }

      onHashChanged( result.hash );
    })
    .catch( failed )
    .finally(() => {
    });

    setAborter( ac );
  
    function failed(){
      console.log( "Failed" );
    } 
  } // _getHash

  function cleanSort(){
    const newSort = [],
          qty = tableSort.length;

    const tableFields = [...defaultFields, ...columns];
    
    for( let i = 0; i < qty; i++ ) {
      const { sort, field } = tableSort[i];

      const sortField = fields.find( f => f.id === field );
      if( !sortField ) {
        // field doesn't exists
        continue;
      }

      const isInTable = tableFields.find( f => { return ( typeof f === 'string' && f === sortField.name ) || ( f.name === sortField.name ) });
      if( !isInTable ) {
        // field is not in current set of columns
        continue;
      }

      newSort.push( { sort, field } );
    }

    if( newSort.length !== qty ) {
      setTableSort( newSort );
    }   
  } // cleanSort

  function onHashChanged( hash ){
    window.history.pushState( null, null, process.env.PUBLIC_URL + '/' + hash );
    
    setHash( hash );
  }


  useEffect(() => {
    let lssidebarCategoriesOpen = [];
    if( localStorage && localStorage.getItem('sidebarCategoriesOpen') ) {
      try {
        lssidebarCategoriesOpen = JSON.parse( localStorage.getItem('sidebarCategoriesOpen') );
        if( lssidebarCategoriesOpen && Array.isArray(lssidebarCategoriesOpen) ) {
          setSidebarCategoriesOpen( lssidebarCategoriesOpen );
        }
      }
      catch(error){
        localStorage.removeItem('sidebarCategoriesOpen');
      }
    }

    loadConfig();

    window.addEventListener('resize', () => {
      startResizing();
      setTimeout( endResizing, 500 );
    });
  }, []);

  useEffect(() => {
    if( fields && fields.length && !configLoaded ) {
      // console.log( "THE FIELDS", JSON.parse( JSON.stringify( fields ) ) );
      setConfigLoaded( true );
    }
  }, [fields]);

  useEffect( () => {
    if( !configLoaded ) {
      return;
    }

    if( hash ) {
      loadHashConfig();      
    }
    else {
      setHashBuildActive(true);
    }  
  }, [configLoaded]);

  useEffect(() => {
    const str = search.toLowerCase();
    const placesList = places.filter( place => place.label.toLowerCase().includes( str ) );
    const countiesList = counties.filter( county => county.label.toLowerCase().includes( str ) );
    setLocationSuggestions( [...placesList,...countiesList] );
  }, [search])

  useEffect( () => {
    getHash();
  }, [
    configLoaded,
    mapBounds,
    columns,
    mapField,
    lockedFips,
    tableSort,
    tableFilter,
    populationThreshold,
    level,
    location,
    maskLocation,
    tableWidth,
    mapWidth,
    indexVariables
    ]); // get hash 

  useEffect( () => {
    if( !mapField ) {
      setMapField('population');
    }
  }, [mapField])


  const columnsList = getCategoryChildren( null, categories );
  const levelsOptions = () => {
    const options = [];

    for( let k in levels ) {
      options.push( <option key={k} value={k}>{ levels[k] }</option> );
    }

    return options;
  };

  const headerHeight = header?.current?.getBoundingClientRect().height ?? 0;

  const allTableFields = [...defaultFields, ...columns];

  return (
    <>
    { ( configLoaded && hashBuildActive ) ?
        <div className="flex flex-col h-screen text-sm"
          onClick={ globalClickListener }>

          <header ref={header} className="flex justify-between p-4 flex-none">
            <div className="text-xl font-semibold">Michigan Criminal Justice Explorer</div>

            <div className="flex gap-4">
              <button className="text-black/30"
                onClick={() => { localStorage.clear(); window.location.assign( process.env.PUBLIC_URL ) } }>reset map</button>

              <div className="search-box relative" 
                    onMouseEnter={ () => setOpenLocationSuggestions(true) }>
                <input className="search-input" 
                        type="search" 
                        name="search" 
                        placeholder="Search location..." 
                        value={search} 
                        onFocus={() => setOpenLocationSuggestions(true) }
                        onInput={(event) => setSearch( event.target.value ) } 
                        />

                { openLocationSuggestions && <div className="search-results">
                  { locationSuggestions.map( item => (
                      <button onClick={ () => {
                            setRecenter( true );
                            setMaskLocation( true );
                            changeLocation( item.code);
                            setOpenLocationSuggestions( false );
                            setSearch( "" );
                          }
                        } 
                        key={ item.code }
                        className="search-result"
                        disabled={ item.code == location }
                        >

                        <Icon name='location-dot' />

                        { item.code == location && 
                          <span className="inline-block text-xs leading-normal text-white bg-slate-400 px-1 rounded align-middle">
                            current
                          </span> }

                        { item.label }
                      </button>
                    )
                  )}
                </div> }
              </div>
            </div>
          </header>

          <div className="flex flex-col lg:flex-row flex-auto 
                          relative
                          w-full max-w-full 
                          border-t border-slate-300 gap-4">

            <div className={ cn( { '--hidden': !columnsPanel }, 'filter' ) }
              style={ { height: `calc( 100vh - ${headerHeight}px - 1px )` }}>
              <div className={ cn( { "hidden": !columnsPanel }, "flex flex-col gap-4 justify-between h-full overflow-auto pr-4 -mr-4 scrollbar" ) }>
                { 
                  buildSidebar( columnsList )
                }

                <div className="flex flex-col gap-2">
                  { <VideoButton link={ config?.tutorial } /> }

                  <div className={ cn( { "hidden": !columnsPanel }, "relative" ) }>
                    <button className="w-full flex justify-between button pl-2 pr-3 pt-[11px] pb-2 rounded-lg 
                                      bg-theme-grey text-theme-blue hover:text-theme-blue/80 hover:bg-theme-grey/80
                                      text-xs font-semibold leading-tight"
                      onClick={ () => {
                        setIdxVar({
                          id: +(new Date),
                          label: "",
                          vars: []
                        });
                      } }>
                      <span>Create Index Variable</span>
                      <span><Icon name="plus" /></span>
                    </button>

                    { idxVar && 
                    <div className="fixed z-10 left-72 bottom-4 bg-white p-4 rounded border border-gray-600 shadow-sm
                      flex flex-col gap-6">
                      <ol className="bg-gray-200 text-gray-800 list-decimal
                        rounded py-3 px-6">
                        <li>Select Variable From The Left</li>
                        <li>Select if High or Low values are Of intereset</li>
                        <li>Assign Weights to each Variable</li>
                        <li>Give the variable a descriptive name</li>
                      </ol>

                      <table>
                        <thead>
                          <tr>
                            <th className="min-w-[10rem]">Variable Name</th>
                            <th className="px-2">High/Low</th>
                            <th className="px-2">Weight</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="leading-8">
                          { idxVar.vars.map( (v,i) => ( 
                              <tr key={i}>
                                <td>{ fields.find( f => f.name == v.name ).label }</td>
                                <td>
                                  <select 
                                    value={ v.dir }
                                    onChange={ (e) => updateIdxVar( {...v, dir: e.target.value } ) }>
                                    <option value="high">High</option>
                                    <option value="low">Low</option>
                                  </select>
                                </td>

                                <td>
                                  <input type="text"
                                    className="w-12 text-center"
                                    value={ v.weight }
                                    onChange={ (e) => updateIdxVar( {...v, weight: e.target.value } ) } />
                                </td>

                                <td>
                                  <button className="text-xs text-gray-400"
                                    onClick={ () => removeIdxVar( v.name ) }>
                                    <Icon name="xmark" />
                                  </button>                                
                                </td>
                              </tr>
                            ) 
                          )}

                        </tbody>
                      </table>

                      <div>
                        <div>Name</div>
                        <input 
                          type="text"
                          className="w-full px-1"
                          required={true}
                          value={ idxVar.label } 
                          onChange={ (e) => {
                              const newIdxVar = JSON.parse( JSON.stringify(idxVar) );
                              newIdxVar.label = e.target.value;
                              setIdxVar( newIdxVar );
                            } 
                          } />
                      </div>

                      <div className="flex gap-8 justify-between">
                        <div>
                          { idxVar?.name && 
                              <button className="button button-danger"
                                onClick={ () => {
                                  const newIndexVariables = JSON.parse( JSON.stringify( indexVariables ) );
                                  const newCategories = JSON.parse( JSON.stringify( categories ) );                          
                                  
                                  const varIdx = newIndexVariables.findIndex( v => v.name == idxVar.name );
                                  const catIdx = newCategories.findIndex( c => c.name == ( 'cat_' + idxVar.name) );

                                  newIndexVariables.splice( varIdx, 1 );
                                  newCategories.splice( catIdx, 1 );

                                  if( fields.includes( idxVar.name ) ) {
                                    toggleColumn( idxVar.name );

                                    // add or update record in allFields
                                    const newFields = JSON.parse( JSON.stringify( fields ) ),
                                          fieldIdx = newFields.findIndex( f => f.name == idxVar.name );

                                    newFields.splice( fieldIdx, 1 );
                                    setFields( newFields );

                                    if( newFields.length ) {
                                      setMapField( newFields[0].name );
                                    }
                                  }
        
                                  setIndexVariables( newIndexVariables );
                                  setCategories( newCategories );
                                  localStorage.setItem('indexVariables', JSON.stringify(newIndexVariables) );

                                  // close popup
                                  setIdxVar( null );                                  
                                }}>
                                Delete
                              </button>
                          }
                        </div>

                        <div className="flex gap-2">
                          <button className="button" onClick={ () => setIdxVar( null ) }>Cancel</button>

                          <button className="button button-primary" onClick={ () => {
                            const newIndexVariables = JSON.parse( JSON.stringify( indexVariables ) );
                            const newCategories = JSON.parse( JSON.stringify( categories ) );                          
                            const newIdxVar = JSON.parse( JSON.stringify( idxVar ) );

                            // add new fields as a category
                            if( !newIdxVar.name ) {
                              newIdxVar.name = '_idx_' + (+new Date());

                              newIndexVariables.push( newIdxVar );

                              newCategories.push({
                                id: +(new Date()),
                                icon: null,
                                label: newIdxVar.label,
                                name: 'cat_' + newIdxVar.name,
                                parent: 999999
                              });
                            }
                            else {
                              const idx = newIndexVariables.findIndex( f => f.name == newIdxVar.name );
                              newIndexVariables.splice( idx, 1, newIdxVar );

                              const catIdx = newCategories.findIndex( c => c.name == newIdxVar.name || c.name == 'cat_' + newIdxVar.name );
                              if( catIdx === -1 || !newCategories[ catIdx ] ) {
                                newCategories.push({
                                  id: +(new Date()),
                                  icon: null,
                                  label: newIdxVar.label,
                                  name: 'cat_' + newIdxVar.name,
                                  parent: 999999
                                });
                              }
                              else {
                                newCategories[ catIdx ].label = newIdxVar.label;
                              }
                            }

                            setIndexVariables( newIndexVariables );
                            setCategories( newCategories );

                            localStorage.setItem('indexVariables', JSON.stringify(newIndexVariables) );

                            // add new index variable to selected columns
                            if( !columns.includes( newIdxVar.name ) ) {
                              toggleColumn( newIdxVar.name );
                            }

                            // add or update record in allFields
                            const newFields = JSON.parse( JSON.stringify( fields ) ),
                              existingField = newFields.find( f => f.name == newIdxVar.name );
                            if( existingField ) {
                              if( existingField.label != newIdxVar.label ) {
                                existingField.label = newIdxVar.label;
                                setFields( newFields );
                              }
                            }
                            else {
                              // new field, we should add it to the fields
                              const maxFieldId = newFields.reduce( (prev,cur) => {
                                if( prev && prev.id > cur.id ) {
                                  return prev.id;
                                }
                                return cur.id;
                              });

                              newFields.push({
                                id: maxFieldId + 1,
                                category_id: newCategories.find( c => c.name == 'cat_' + newIdxVar.name )?.id,
                                label: newIdxVar.label,
                                name: newIdxVar.name,
                                step: 0.01
                              });

                              setFields( newFields );
                            }
                        
                            // close popup
                            setIdxVar( null );                          
                          }}>Apply</button>
                        </div>
                      </div>                    
                    </div> 
                    }
                  </div>
                </div>
              </div>

              <button className="--close text-xs" onClick={ () => {
                  setColumnsPanel( !columnsPanel );
                  setTimeout( () => {
                    startResizing();
                    setTimeout( endResizing, 100 );
                  }, 1000 );
                }}>
                { columnsPanel ? <Icon name='angle-left' /> :  <Icon name='angle-right' />}
              </button>
            </div>

            <div className="resize-container" 
                  ref={resizeContainer}
                  onMouseMove={ resize }  
                  onMouseUp={ endResizing }>

              <div className="table-container" 
                    style={{ width: `calc( ${tableWidth}% - 1rem - 0.5px )` }}>

                <div className="--menu flex justify-between items-center mb-5">
                  <div>
                    <span className="text-lg leading-6 font-semibold">Data Table</span>
                  </div>


                  <div className="flex gap-6 text-xs leading-4 font-semibold">
                    <DataSort 
                      allFields={ fields }
                      fields={ [...defaultFields, ...columns] }
                      sort={ tableSort }
                      setSort={ ( sort ) => {
                        localStorage?.setItem('dataSort', JSON.stringify( sort ) )
                        setTableSort( sort ) 
                        }
                      } 
                      />

                    <DataFilter
                      allFields={ fields }
                      fields={ [...defaultFields, ...columns] }
                      onSet={ ( type, fltr ) => {
                          if( type == 'select' ) {
                            setPopulationThreshold( null );
                            setTableFilter( fltr ) 
                          }
                          else {
                            setPopulationThreshold( fltr );
                            setTableFilter( null );
                          }
                        }
                      } />

                    <button className="flex gap-2 items-center"
                      onClick={ () => exportTable() }
                      >
                      { doingTableExport ? <span className="animate-spin"><Icon name='hourglass-half' /></span> : <Icon name='upload' /> }
                      <span>Export</span>
                    </button>
                  </div>
                </div>

                <div onMouseLeave={() => {
                  setMapFips(null)
                }}>
                  { fields.length && 
                    <DataTable 
                      location={ maskLocation ? location : null } 
                      level={level} 
                      field={mapField}
                      fips={mapFips}
                      fields={ [...defaultFields, ...columns] }
                      allFields={ fields }
                      mapBounds={ mapBounds }
                      mapZoom={ mapZoom }
                      indexVariables={ indexVariables }
                      updateMap={ (data) => { setStatsData(data) } }
                      onFipsSelected={ (fips) => setMapFips(fips)  }
                      lockedFips={ lockedFips }
                      onFipsLocked={ (list) => setLockedFips( list ) }
                      setMapField={ updateMapField }
                      removeField={ (k) => {
                        toggleColumn( k, null );
                      }}
                      getFips={ getFips }
                      sort={ tableSort }
                      setSort={ setTableSort }
                      queryFilter={ tableFilter }
                      threshold={ populationThreshold }
                      />
                  }
                </div>
              </div>

              <div className="resizer" ref={resizer}>
                <div className="--indicator"></div>

                <button onMouseDown={ startResizing }
                      >
                  <Icon name='arrows-left-right' /> 
                </button>
              </div>

              <div className="map-container" style={{ width: `calc( ${mapWidth}% - 1rem - 0.5px )`, overflowX: isResizing ? 'hidden' : null }}>
                <div className="flex justify-between">
                  <span className="text-title">
                    <span className="text-lg leading-6 font-semibold">Map</span>
                    
                    <span className="text-base inline-flex gap-4 ml-4 hidden">
                      <button onClick={ () => setMap( 0 ) } 
                        className={ cn({
                          'font-semibold': !map
                          })
                        }>Google Maps</button>
                      <button onClick={ () => setMap( 1 ) }
                        className={ cn({
                          'font-semibold': map
                          })
                        }>MapBox</button>
                    </span>
                  </span>

                  <button className="flex gap-2 items-center text-xs leading-4 font-semibold"
                    onClick={ () => exportMap() }
                    >
                    { doingMapExport ? <span className="animate-spin"><Icon name='hourglass-half' /></span> : <Icon name='upload' /> }
                    <span>Export</span>
                  </button>
                </div>

                <div className="flex flex-wrap justify-between items-center gap-2  text-xs leading-4 font-semibold">
                  <div className="flex items-center gap-2">
                    <span>Data:</span>
                    
                    <select value={ mapField } 
                      onChange={ (event) => updateMapField( event.target.value ) }>
                      { 
                      allTableFields.map( ( f ) => {
                        const k = typeof f == 'object' ? f.name : f,
                              field = fields.find( field => field.name === k );
                        
                        if( ['fipsCode','fipsLabel'].includes(k) ) {
                          return null;
                        }

                        return <option value={ k } key={ k }>{ ( field && ( field.label || field.name ) ) || k }</option>
                      }) 
                      }
                    </select>

                    <select value={level} 
                      onChange={ (event) => changeLevel( event.target.value ) }>
                      { levelsOptions() }
                    </select>
                  </div>

                  <div className="flex items-center gap-2">
                    <span>Location: </span>
                    
                    <select value={location} onChange={ (event) => {
                          setRecenter( true );
                          setMaskLocation( true );
                          changeLocation( event.target.value );
                        }
                      }>
                      <option value="">Michigan</option>
                      { locationHistory.map( (fips,index) => (
                          <option value={ fips } key={ index }>{ [...counties,...places].find( l => l.code == fips )?.label ?? fips }</option>
                        ) 
                      )}
                    </select>
                  </div>

                  <div>
                    <button onClick={ () => {
                        setMaskLocation( !maskLocation );
                        localStorage.setItem('maskLocation', !maskLocation )
                      }}
                      className={ cn({ 'flex items-center gap-2': true, 'text-theme-blue': maskLocation }) }
                      >
                      <Icon name={ maskLocation ? 'square-check' : 'square' } />

                      <span className="text-left leading-none">Mask location</span>
                    </button>
                  </div>
                </div>

                
                <div className="flex-auto"
                  onMouseLeave={() => {
                    setMapFips(null)
                  }}>
                  { !isResizing && <>
                    { 
                      <MapBoxWrapper 
                          data={ statsData } 
                          location={ maskLocation ? location : null } 
                          field={mapField}
                          fips={mapFips}
                          lockedFips={lockedFips}
                          onFipsLocked={ (list) => setLockedFips( list ) }
                          level={level}
                          levels={levels}
                          reload={!isResizing} 
                          setMapFips={ setMapFips }
                          setMapBounds={ setMapBounds }
                          setMapZoom={ setMapZoom }
                          setMap={ setMapContainer }
                          onRecenter={ () => {
                            setRecenter( false );
                          }}
                          allFields={ fields }
                          recenter={ recenter }

                          mapHeight={ '100%' }

                          onChoroplethChange={ () => {
                            getHash();
                          }}
                          /> 
                    }
                    </>
                  }
                </div>


              </div>
            </div>
          </div>
        </div>
        : <div className="text-xl font-semibold text-center py-16">App is loading...</div>
    }
    </>
  );
}

export default App;

