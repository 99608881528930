import { useEffect, useState } from "react";

import Icon from "./Icon";

const DataSort = ({
    sort,
    setSort,
    fields,
    allFields
  }) => {

  const [open, setOpen] = useState( false );
  const [backup,setBackup] = useState( null )
  
  const toggle = () => {
    if( open ) {
      _setSort( backup );
    }
    else {
      setBackup( sort );
    }

    setOpen( !open );
  }
  
  const _setSort = ( newSort ) => {
    setSort( newSort );    
  }

  const update = ( index, param, value ) => {
    const newSort = JSON.parse( JSON.stringify( sort ) );
    newSort[ index ][ param ] = value;
    _setSort( newSort );
  }

  const moveUp = ( index ) => {
    if( !index ) { return; }
    const newSort = JSON.parse( JSON.stringify( sort ) );
    newSort.splice( index - 1, 0, newSort.splice( index, 1 )[0] );
    _setSort( newSort );
  }

  const moveDown = ( index ) => {
    if( index + 1 === sort.length ) { return; }
    const newSort = JSON.parse( JSON.stringify( sort ) );
    newSort.splice( index + 1, 0, newSort.splice( index, 1 )[0] );
    _setSort( newSort );
  }

  const remove = ( index ) => {
    const newSort = JSON.parse( JSON.stringify( sort ) );
    newSort.splice( index, 1 );
    _setSort( newSort );
  }

  const add = ( index ) => {
    const newSort = JSON.parse( JSON.stringify( sort ) );
    newSort.push({ "field": "", "sort": "ASC" });
    _setSort( newSort );
  }

  const apply = () => {
    setBackup( sort );
    localStorage?.setItem( 'dataSort', JSON.stringify( sort ) );
    setOpen( false );
    setSort( sort );
  }

  const hasSort = !!sort.length;

  return (
    <div className="relative">

      
      <button className={ `flex gap-2 items-center px-2 ${open && 'bg-gradient-to-t from-gray-200 via-gray-50'}` } 
        onClick={ toggle }>
        <Icon name='arrow-down-wide-short' />
        <span>Sort By</span>  

        { !open && hasSort && <span className="w-[3px] h-[3px] absolute top-[20%] right-0 bg-red-400 rounded-full animate-pulse"></span> }
      </button>
    

      { 
        open && fields?.length && allFields?.length && (
          <div className="absolute left-1/2 bottom-0 z-10
                          -translate-x-1/2 translate-y-full 
                          p-4 bg-white border border-solid border-gray-400 shadow">
            <div className="flex flex-col gap-6">
              { sort.map( (s,i) => (
                <div className="flex gap-4 items-center" key={ i }>
                  <div className="flex gap-1 text-xs text-gray-400">
                    <button className={ !i ? 'text-gray-100' : '' }
                      onClick={ () => moveUp(i) }>
                      <Icon name="arrow-up" />
                    </button>

                    <button className={ (i === sort.length - 1) ? 'text-gray-100' : '' }
                      onClick={ () => moveDown(i) }>
                      <Icon name="arrow-down" />
                    </button>
                  </div>

                  <div className="flex gap-2 items-center">
                    <span className="text-sm">Field</span>
                    <select value={ s.field } 
                      onChange={ (e) => { update( i, 'field', e.target.value ) }}>
                      <option key="" value="">Please select</option>
                      { 
                        fields.map( _field => {
                          const fn = _field?.name ?? _field;

                          const field = allFields.find( f => f.name === fn );

                          if( !fn || !field ) {
                            return null;
                          }

                          return (
                            <option key={ fn } 
                                  value={ field.id } 
                                  checked={ field.id === s.field }>
                              { field?.label || fn }
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>

                  <div className="flex gap-2 items-center">
                    <span className="text-sm">Sort</span>
                    <select value={ s.sort }
                      onChange={ (e) => { update( i, 'sort', e.target.value ) } }>
                      <option value="ASC" checked={ !s.sort || s.sort === 'ASC' }>ASC</option>
                      <option value="DESC" checked={ s.sort === 'DESC' }>DESC</option>
                    </select>
                  </div>

                  <button className="text-xs text-gray-400"
                    onClick={ () => remove(i) }>
                    <Icon name="xmark" />
                  </button>
                </div>
              ))}

              <div className="flex gap-8 justify-between">
                <button className="button button-success"
                  onClick={ (e) => add() }>
                  <Icon name="plus" />
                </button>

                <div className="flex gap-2">
                  <button className="button" onClick={ toggle }>Cancel</button>
                  <button className="button button-primary" onClick={ apply }>Apply</button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default DataSort