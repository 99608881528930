import { useRef, useState } from "react";
import { sha256 } from "js-sha256";

const Protected = ( props ) => {
  const pass = useRef(null);

  const [status,setStatus] = useState( localStorage?.getItem('loggedIn') );

  const onSubmit = (event)  => {
    const hashed = sha256( pass.current.value );

    if( hashed !== "289a782b6ee88f6842687552945b1bcbfb42ea67d8f76b43e120aa25a7a1af3d" ) {
      setStatus(false);
      return;
    }

    setStatus( true );
    localStorage?.setItem('loggedIn', true);
  }

  return (
    status ? <>{ props.children }</> : 
    <div className="fixed left-0 top-0 w-screen h-screen z-100 bg-black/10">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-w-full max-h-full bg-white shadow-md shadow-grey 
        px-8 py-12 rounded
        flex flex-col gap-4 text-center">
        <p className="text-2xl underline underline-offset-2 decoration-theme-blue">Michigan Criminal Justice Explorer</p>

        <p>Please enter a password</p>

        <p className="flex gap-2 mx-auto">
          <input type="password" 
            ref={pass} 
            placeholder="* * * * * *"
            autoComplete="off"
            autoFocus={true}
            className="shaddow-md border-theme-grey border-2 rounded px-2" 
            onSubmit={ onSubmit }/>

          <button onClick={ onSubmit }
            className="border border-solid rounded border-theme-grey
                bg-theme-light-grey px-4">Submit</button>
        </p>

        { false === status && <p className="text-orange-700">Wrong password</p> }
      </div>
    </div>
  );
}

export default Protected;